import {HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, distinctUntilChanged, shareReplay, takeLast} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {ErrorService} from '@app/interceptors/error.service';
import {getLogger} from '@shared/utils/log';

/**
 * The interceptor to intercept sent requests to the server and make what needed with that.
 * For instance to add parameters to the url or add a token and so on.
 */
@Injectable()
export class HttpReqInterceptor implements HttpReqInterceptor {
  private logger = getLogger();
  private errorService = inject(ErrorService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.info(`${request.method} ${request.url}`);
    return this.sendRequest(request, next);
  }

  /**
   * Sending request if cache doesn't have a request.
   * If we get an error from a  server It shows popup window with that error.
   * If It doesn't have a data (data is empty) Info dialog will be open with that information
   *
   * @param request - HttpRequest
   * @param next    - HttpHandler
   *
   * @returns Observable<HttpEvent<any>>
   */
  private sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      distinctUntilChanged(),
      takeLast(1),
      catchError((error: HttpErrorResponse) => this.errorService.handleError(error)),
      shareReplay(),
    );
  }
}

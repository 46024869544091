import {ChangeDetectionStrategy, Component, inject, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {translationMap} from '@app/core-translation';
import {getLogger, loggerConfig} from '@shared/utils/log';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [Location],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'dashboard';
  private router = inject(Router);
  private location = inject(Location);

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private config: PrimeNGConfig,
    private keycloakService: KeycloakService,
  ) {
    if (environment.production) {
      this.keycloakService.loadUserProfile().then((profile) => {
        const attributes = profile?.attributes as {logging: string};
        const loggerEnabled = attributes?.logging[0] === 'enabled';
        if (!loggerEnabled) {
          const logger = getLogger('AppComponent');
          logger.info('Logging is disabled');
        }
        loggerConfig.isLoggerEnabled = loggerEnabled;
      });
    }
  }

  ngOnInit() {
    if (this.locale === 'de') {
      this.config.setTranslation(translationMap);
    }

    const href = this.location.path(false) || '/map';
    this.router.navigateByUrl(href);
  }
}

<p-dropdown
  (onChange)="changeLanguage()"
  [options]="languages"
  [(ngModel)]="selectedLanguage"
  optionLabel="name"
  class="language-switcher"
>
  <ng-template pTemplate="selectedItem">
    <div class="language-item" *ngIf="selectedLanguage">
      <fa-icon class="list-item-icon" [icon]="faGlobe"></fa-icon>
      <span i18n="@@language-switcher.language">Language: </span>
      <span class="selected-language-container">
        <img
          [src]="'assets/flags/' + selectedLanguage.code.toLowerCase() + '.svg'"
          class="flag-icon mr-2"
          [alt]="selectedLanguage.name"
        />
        <span>{{ selectedLanguage.name }}</span>
      </span>
    </div>
  </ng-template>
  <ng-template let-language pTemplate="item">
    <div class="language-item">
      <img [src]="'assets/flags/' + language.code.toLowerCase() + '.svg'" class="flag-icon" />
      <div class="option-label">{{ language.name }}</div>
    </div>
  </ng-template>
</p-dropdown>
